.app {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  background-color: #282c34;
  color: #fff;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media screen and (max-width: 1024px) {
    display: block;
  }
}

header {
  display: none;

  h1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #d9d9e3;
    margin: auto;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    background: #353441;
    border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    height: 34px;
  }
}

.menu {
  padding: 4px 0 0 12px;

  @media screen and (max-width: 1024px) {
    position: absolute;
  }
}

.menu button {
  background: none;
  border: 0;
}

nav {
  display: none;
  background-color: #4c4f5f;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  @media screen and (max-width: 1024px) {
    display: flex;
  }
}

.nav-items {
  padding: 8px;
  background-color: #212122;
  width: 100%;
  max-width: 340px;
}

.nav-close-icon {
  width: 20%;
  margin: 10px;
}

.nav-prompt {
  display: flex;
  align-items: center;
  padding: 10px;
  column-gap: 20px;
  margin: 5px 0;
  text-align: left;

  p {
    font-size: 14px;
    line-height: 20px;
    color: #ececf1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;
    margin: 0;
  }

  span {
    animation: fadeInChar 1s ease-in forwards;
    opacity: 0;
  }
}

#botMessage span {
  margin: 0;
  padding: 0;
  text-indent: 0;
  white-space: pre-wrap;
  position: relative;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

@keyframes fadeInChar {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

nav svg {
  float: left;
}

.side-menu {
  width: 244px;
  padding: 8px;
  background-color: #202022;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  .nav-prompt-wrapper {
    border-bottom: 1px solid #ffffff33;
    padding: 10px;
    height: 55vh;
    overflow-y: auto;
  }
}

.side-menu-button {
  border: 1px solid hsl(0deg 0% 100% / 20%);
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;

  span {
    font-size: 20px;
    font-weight: 500;
    padding-left: 6px;
    padding-right: 10px;
  }
}

.nav-prompt:hover,
.side-menu-button:hover {
  cursor: pointer;
  background-color: hsla(240, 9%, 59%, 0.1);
}

.chat-box {
  flex: 1;
  background-color: #353540;
  position: relative;
  line-height: 24px;
  color: #d1d5db;
  font-size: 16px;

  @media screen and (max-width: 1024px) {
    position: static;
    height: calc(100vh - 34px);
    overflow: hidden;
  }
}

.intro-section {
  text-align: left;
  padding: 20px;

  @media screen and (max-width: 1024px) {
    padding: 20px 20px 40px 20px;
    font-size: 14px;
  }

  h1 {
    padding-top: 20px;
    line-height: 33px;
    text-align: center;
    margin-top: 20vh;
    font-size: 2.25rem;

    @media screen and (max-width: 1024px) {
      margin-top: 1rem;
      font-size: 1.5rem;
      white-space: pre-wrap;
    }
  }

  h2 {
    font-size: 16px;
    font-weight: 400;

    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }

  ul {
    list-style-type: square;
  }
}

.chat-log-wrapper {
  height: 80vh;
  overflow-y: auto;

  @media screen and (max-width: 600px) {
    height: calc(80vh - 34px);
  }
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #575969;
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #575969;
}

::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

.chat-prompt-wrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  column-gap: 25px;

  @media screen and (max-width: 1024px) {
    padding: 12px;
  }
}

.user-icon {
  transform: scale(0.6);

  svg {
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    transform: scale(0.5);
  }
}

.bot-message-main-container {
  width: 100%;
  background-color: #444654;
}

.bot-message-wrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;

  @media screen and (max-width: 1024px) {
    padding: 12px;
  }
}

.error-message {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openai-icon {
  transform: scale(0.5);

  @media screen and (max-width: 1024px) {
    transform: scale(0.4);
  }
}

.chat-prompt,
.bot-message {
  margin-top: auto;
  margin-bottom: auto;
}

.chat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 0.125rem;
  object-fit: contain;
  object-position: center;

  @media screen and (max-width: 1024px) {
    width: 30px;
    height: 30px;
  }
}

.input-prompt-wrapper {
  padding: 8px;
  left: 0;
  right: 0;
  bottom: 0;
  position: relative;
  padding-top: 20px;
  margin-bottom: 2rem;

  @media screen and (min-width: 768px) {
    position: absolute;
  }

  @media screen and (max-width: 1024px) {
    position: absolute;
    margin-bottom: 0;
    padding-top: 10px;
    background-color: #353540;
  }

  .input-prompt-icon {
    background-color: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 0.8rem;
    bottom: 0.8rem;
    padding: 0;
    width: 13.5px;
    height: 13.5px;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .input-prompt {
    width: 80%;
    margin: auto;
    position: relative;
  }
}

.input-prompt-textarea {
  width: 100%;
  max-height: 200px;
  height: 28px;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  padding-left: 1rem;
  resize: none;
  font-size: 16px;
  background-color: #41414e;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  overflow-y: hidden;
  box-sizing: border-box;
  height: 100%;
  padding-right: 1.75rem;
}

.footer {
  font-size: 12px;
  width: 80%;
  margin: auto;
  padding-top: 0.5rem;

  a {
    color: #fff;
  }

  .editor {
    margin-top: 1rem;
  }
}

.bot-cursor {
  position: relative;
  display: inline-block;
  line-height: 1.25rem;
  height: .875rem;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 5px;
    display: inline-block;
    background-color: #fff;
    vertical-align: top;
    width: 8px;
    height: 16px;
    line-height: 20px;
    animation: blink 1s step-end infinite;
  }
}

@keyframes blink {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}
